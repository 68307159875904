import React, { ReactElement } from 'react';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import { Heading } from '@mopfis/scadi/dist/components/text/heading/heading';

import { LoadingWrapperComponent } from 'components/common/loading-wrapper';
import { ORDER_OVERVIEW_NAMESPACE } from 'constants/translations';

const OrderOverviewTable = dynamic(
  () => import('scenes/order-overview/order-overview-table').then(mod => mod.OrderOverviewTable),
  {
    ssr: false,
    loading: LoadingWrapperComponent,
  }
);

const OrderOverview = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Heading type="h1" variant="orderOverviewTitle">
        {t(`${ORDER_OVERVIEW_NAMESPACE}:list`)}
      </Heading>
      <OrderOverviewTable />
    </>
  );
};

export default OrderOverview;
