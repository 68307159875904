import React, { ReactElement } from 'react';

import { SquareLoader } from 'components/loaders/square-loader';
import { LOADING_WRAPPER_TEST_ID } from 'constants/tests/test-ids';
import { LoadingWrapperContainer } from './style';

export function LoadingWrapperComponent(): ReactElement {
  return (
    <LoadingWrapperContainer data-testid={LOADING_WRAPPER_TEST_ID}>
      <SquareLoader isLoading />
    </LoadingWrapperContainer>
  );
}
